import { Job, JobTypeEnum } from '@libs/graphql-types';
import {
  JOB_TYPES_LABELS_MAP,
  JOB_TYPE_MAP,
} from '@libs/ui/components/job/utils/consts';

import { formatDate, formatRate, getJobCapacity } from './common';

export const FINDERS_FEE_TOOLTIP =
  'Up to 6 months or the project duration, whichever is lower';

interface JobFieldOption {
  value: string;
  id: string;
  label?: string;
  hint?: string;
}

export enum JobFieldType {
  FindersFee = 'finders_fee',
  Rate = 'rate',
  StartDate = 'start_date',
  EndDate = 'end_date',
  Capacity = 'capacity',
  Type = 'type',
  Client = 'client',
  Category = 'category',
  Location = 'location',
  LocationType = 'location_type',
  Fee = 'fee',
  Salary = 'salary',
  City = 'city',
  Country = 'country',
}

const getRateFromJob = (job: Job): JobFieldOption => ({
  id: JobFieldType.Rate,
  value:
    formatRate({
      min: job?.rate_min,
      max: job?.rate_max,
      period: job.type === JobTypeEnum.Permanent ? 'month' : 'hour',
      isNegotiable: job.is_rate_negotiable,
    }) || '',
  label: 'Rate:',
});
const getJobStartDate = (job: Job): JobFieldOption => ({
  id: 'start_date' as const,
  value: formatDate(job.start_date) || '',
  label: 'Starting date:',
});
const getJobEndDate = (job: Job): JobFieldOption => ({
  id: JobFieldType.EndDate,
  value: formatDate(job.end_date) || formatDate(job.campaign_end_date) || '',
  label: 'End date:',
});
const getJobCapacityField = (job: Job): JobFieldOption => ({
  label: 'Capacity:',
  value: getJobCapacity(job) || '',
  id: JobFieldType.Capacity,
});
const getJobType = (job: Job): JobFieldOption => ({
  label: 'Type:',
  value: JOB_TYPES_LABELS_MAP[job?.type || JobTypeEnum.Freelance],
  id: JobFieldType.Type,
});
const getJobClient = (job: Job): JobFieldOption => ({
  label: 'Client:',
  value: job.client || '',
  id: JobFieldType.Client,
});
const getJobCategory = (job: Job): JobFieldOption => ({
  label: 'Category:',
  id: JobFieldType.Category,
  value: job.category?.name || '',
});

const getJobCity = (job: Job): JobFieldOption => ({
  label: 'City:',
  id: JobFieldType.City,
  value: job.city || '',
});

const getJobCountry = (job: Job): JobFieldOption => ({
  label: 'Country:',
  id: JobFieldType.Country,
  value: job.country || '',
});
const getJobLocationType = (job: Job): JobFieldOption => {
  const locationType = job.location_type!;
  const locationTypeText = JOB_TYPE_MAP[locationType];

  return {
    label: 'Location type:',
    id: JobFieldType.LocationType,
    value: locationTypeText,
  };
};
const getJobLocation = (job: Job): JobFieldOption => ({
  label: 'Location:',
  id: JobFieldType.Location,
  value: job.location || '',
});
const getJobFindersFee = (job: Job): JobFieldOption => ({
  label: 'Finder’s fee:',
  id: JobFieldType.Fee,
  value: !job?.finders_fee
    ? ''
    : formatRate({
        min: job.finders_fee!,
        period: job?.type === JobTypeEnum.Freelance ? 'hour' : 'month',
      }) || '',
  hint: FINDERS_FEE_TOOLTIP,
});
const getJobSalary = (job: Job): JobFieldOption => ({
  label: 'Salary:',
  id: JobFieldType.Salary,
  value:
    formatRate({
      min: job?.salary_min,
      max: job?.salary_max,
      isNegotiable: job.is_salary_negotiable,
      period: job.type === JobTypeEnum.Permanent ? 'month' : 'hour',
    }) || '',
});

const getAllJobFields = (job: Job): Record<string, JobFieldOption> => {
  return {
    [JobFieldType.Rate]: getRateFromJob(job),
    [JobFieldType.Client]: getJobClient(job),
    [JobFieldType.Type]: getJobType(job),
    [JobFieldType.Capacity]: getJobCapacityField(job),
    [JobFieldType.StartDate]: getJobStartDate(job),
    [JobFieldType.EndDate]: getJobEndDate(job),
    [JobFieldType.Category]: getJobCategory(job),
    // [JobFieldType.LocationType]: getJobLocationType(job),
    [JobFieldType.Location]: getJobLocationType(job),
    [JobFieldType.LocationType]: getJobLocationType(job),
    [JobFieldType.City]: getJobCity(job),
    [JobFieldType.Country]: getJobCountry(job),
    [JobFieldType.Fee]: getJobFindersFee(job),
    [JobFieldType.Salary]: getJobSalary(job),
    [JobFieldType.Location]: getJobLocation(job),
    [JobFieldType.FindersFee]: getJobFindersFee(job),
  };
};

const DEFAULT_TALENT_FIELDS = [
  JobFieldType.Rate,
  JobFieldType.StartDate,
  JobFieldType.EndDate,
  JobFieldType.Capacity,
  JobFieldType.LocationType,
  JobFieldType.Location,
  JobFieldType.Country,
  JobFieldType.City,
  JobFieldType.Client,
];

const TALENT_FIELDS = {
  [JobTypeEnum.Permanent]: [
    JobFieldType.Salary,
    JobFieldType.StartDate,
    JobFieldType.EndDate,
    JobFieldType.Capacity,
    JobFieldType.LocationType,
    JobFieldType.Country,
    JobFieldType.City,
    JobFieldType.Client,
  ],
  [JobTypeEnum.Freelance]: DEFAULT_TALENT_FIELDS,
  [JobTypeEnum.Project]: DEFAULT_TALENT_FIELDS,
};

const MATCHERS_FIELDS = {
  [JobTypeEnum.Permanent]: [
    JobFieldType.Salary,
    JobFieldType.FindersFee,
    JobFieldType.StartDate,
    JobFieldType.EndDate,
    JobFieldType.Capacity,
    JobFieldType.LocationType,
    JobFieldType.Country,
    JobFieldType.City,
    JobFieldType.Client,
  ],
  [JobTypeEnum.Freelance]: [
    JobFieldType.Salary,
    JobFieldType.FindersFee,
    JobFieldType.StartDate,
    JobFieldType.EndDate,
    JobFieldType.Capacity,
    JobFieldType.LocationType,
    JobFieldType.Country,
    JobFieldType.City,
    JobFieldType.Client,
  ],
  [JobTypeEnum.Project]: [
    JobFieldType.Salary,
    JobFieldType.FindersFee,
    JobFieldType.StartDate,
    JobFieldType.EndDate,
    JobFieldType.Capacity,
    JobFieldType.LocationType,
    JobFieldType.Country,
    JobFieldType.City,
    JobFieldType.Client,
  ],
};

const DEFAULT_COMPANY_FIELDS = [
  JobFieldType.Rate,
  JobFieldType.StartDate,
  JobFieldType.EndDate,
  JobFieldType.Capacity,
  JobFieldType.Type,
  JobFieldType.Client,
  // JobFieldType.Category,
  JobFieldType.LocationType,
  JobFieldType.Country,
  JobFieldType.City,
  JobFieldType.Fee,
];
const COMPANY_FIELDS = {
  [JobTypeEnum.Permanent]: [
    JobFieldType.Type,
    JobFieldType.Category,
    JobFieldType.Salary,
    JobFieldType.StartDate,
    JobFieldType.EndDate,
    JobFieldType.Capacity,
    JobFieldType.Client,
    JobFieldType.LocationType,
    JobFieldType.Country,
    JobFieldType.City,
    JobFieldType.Fee,
  ],
  [JobTypeEnum.Freelance]: DEFAULT_COMPANY_FIELDS,
  [JobTypeEnum.Project]: DEFAULT_COMPANY_FIELDS,
};

export const getJobFieldsForTalent = (job: Job) => {
  const allFields = getAllJobFields(job);
  const fieldTypesArr = TALENT_FIELDS[job?.type || JobTypeEnum.Freelance];
  return fieldTypesArr
    .map((field) => allFields[field])
    .filter((i) => !!i.value);
};
export const getJobFieldsForMatchers = (job: Job) => {
  const allFields = getAllJobFields(job);
  const fieldTypesArr = MATCHERS_FIELDS[job?.type || JobTypeEnum.Freelance];
  return fieldTypesArr
    .map((field) => allFields[field])
    .filter((i) => !!i.value);
};
export const getJobFieldsForCompany = (job: Job) => {
  const allFields = getAllJobFields(job);
  const fieldTypesArr = COMPANY_FIELDS[job?.type || JobTypeEnum.Freelance];
  return fieldTypesArr
    .map((field) => allFields[field])
    .filter((i) => !!i.value);
};
