import { useEffect } from 'react';

import { useSearchTalentsToSendMessageLazyQuery } from '@libs/graphql-types';

import { FormModel } from '../types';

export const useTalentCount = (values: FormModel) => {
  const [getTalents, { loading, data }] =
    useSearchTalentsToSendMessageLazyQuery();

  useEffect(() => {
    const tags = values.tags.map((i) => i.value) as string[];

    getTalents({
      variables: {
        source_type: 'OWN',
        category_ids: values.category ? [values.category] : undefined,
        tags_ids: tags,
        first: 100000,
        country: values.country,
      },
    });
  }, [getTalents, values]);

  return { count: data?.talentsSearch?.data.length || 0, loading };
};
