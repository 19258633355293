import { useCurrentUser } from 'hooks/auth/useCurrentUser';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import { formatName } from 'utils/talent';

import {
  useCreateAnnouncementMutation,
  User,
  useSearchTalentsToSendMessageLazyQuery,
} from '@libs/graphql-types';
import { FormikSubmit } from '@libs/helpers/form';

import { FormModel } from './types';

export const useTitlesMap = () => {
  const { getData } = useCurrentUser();
  const map = useMemo(() => {
    const { user } = getData();
    const userName = formatName({
      firstName: user?.first_name,
      lastName: user?.last_name,
    });
    const company = (user as User)?.company?.name;
    const companyPart = company ? `from ${company}` : '';

    return {
      WITH_REPLY: `💚 ${userName} ${companyPart} sent you an input request 💚`,
      NO_REPLY: `🎉 ${userName} ${companyPart} sent you an announcement 🎉`,
    };
  }, [getData]);

  return map;
};

export const useSubmitAction = ({ onSuccess }: { onSuccess: VoidFunction }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [getTalents, { loading }] = useSearchTalentsToSendMessageLazyQuery();
  const [createAnnouncement] = useCreateAnnouncementMutation({
    onCompleted: () =>
      enqueueSnackbar('Announcement sent!', { variant: 'success' }),
  });
  const titlesMap = useTitlesMap();

  const onSubmit: FormikSubmit<FormModel> = useCallback(
    async (values) => {
      setIsLoading(true);

      const { category } = values;
      const tags = values.tags.map((i) => i.value) as string[];

      const res = await getTalents({
        variables: {
          source_type: 'OWN',
          category_ids: category ? [category] : undefined,
          tags_ids: tags,
          first: 100000,
          country: values.country,
        },
      });
      const talentsIds = (res.data?.talentsSearch?.data?.map(
        (i) => i.talent?.id!,
      ) || []) as string[];

      if (!talentsIds.length) {
        enqueueSnackbar('No talents to send message', { variant: 'warning' });
      }

      await createAnnouncement({
        variables: {
          title: titlesMap[values.messageType],
          text: values.message,
          need_response: values.messageType === 'WITH_REPLY',
          talents_ids: talentsIds,
          send_email: values.sendEmail,
        },
      });

      setIsLoading(false);
      onSuccess();
    },
    [createAnnouncement, enqueueSnackbar, getTalents, onSuccess, titlesMap],
  );

  return { onSubmit, loading: loading || isLoading };
};
