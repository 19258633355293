import { makeStyles } from '@mui/styles';

import { StylesAccountProps } from './Account';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  textMultiline: {
    margin: 0,
  },
  arrow: {
    margin: theme.spacing(0, 0, 0, 2),
    minWidth: 0,
  },
  positionText: {
    maxWidth: 160,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  itemListAvatar: ({ isSM }: StylesAccountProps) => ({
    ...(isSM ? { minWidth: 'auto' } : {}),
  }),
  skeleton: {
    opacity: 0.1,
    margin: `0 ${theme.spacing(3)}`,
  },
}));

export default useStyles;
